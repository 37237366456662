import { MediaNode } from '@innedit/innedit-type';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import Button from '../Button';
import Media from '../Media';

interface CategoryProps {
  description?: string;
  id: string;
  medias?: MediaNode[];
  title: string;
}

const TextSC = styled.div`
  flex-basis: 300px;
  flex-shrink: 0;
`;

const Category: FC<CategoryProps> = function ({
  description,
  id,
  medias,
  title,
}) {
  const { t } = useTranslation();

  return (
    <div className="flex flex-col md:flex-row space-y-6 md: space-y-0 md:space-x-12 mx-6">
      <TextSC>
        <h3 className="text-2xl mb-6">{title}</h3>
        {description && <p>{description}</p>}
        <Button
          status="neutral"
          to={`${t('categorie.pathname')}${id.toLowerCase()}/`}
        >
          {t('pages.accueil.categories.button.label')}
        </Button>
      </TextSC>

      {medias && (
        <div>
          <div className="grid grid-cols-3 gap-6">
            {medias.map(media => (
              <Media
                key={media.id}
                media={media}
                transformations={['c_contain,b_fcfbf8,r_1.4']}
              />
            ))}
          </div>
        </div>
      )}
    </div>
  );
};

export default Category;
