import { graphql, navigate, PageProps } from 'gatsby';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import Slider from 'react-slick';
import slug from 'slug';

import Carrousel from '../components/Carrousel';
import Category from '../components/Category';
import Content from '../components/Content';
import Layout from '../components/Layout';
import Media from '../components/Media';
import Recherche from '../components/Produits/Recherche';
import SliderSC from '../plugins/slick';

const IndexPage: FC<
  PageProps & {
    data: {
      channel?: {
        accueil?: {
          description?: string;
          features?: {
            id: string;
            libelle: string;
            description?: string;
            medias?: any[];
            traductions?: {
              en?: {
                description?: string;
                libelle?: string;
              };
            };
          }[];
          medias?: any[];
        };
      };
    };
  }
> = function ({ data, location, params: { query: queryPath } }) {
  const { t } = useTranslation();
  const language = location.pathname.replaceAll('/', '');

  const handleOnSubmit = async (values: { q: string }) => {
    const path = slug(values.q);
    window.history.replaceState(
      { path },
      '',
      t('pages.recherche.pathname', { path }),
    );

    await navigate(t('pages.recherche.pathname', { path }));
  };

  const settings = {
    cssEase: 'linear',
    dots: true,
    draggable: true,
    infinite: true,
    pause: false,
    slidesToScroll: 1,
    slidesToShow: 1,
  };

  return (
    <Layout pathname={location.pathname} showTitle title="pages.accueil.title">
      <div className="flex justify-center mb-12">
        <Recherche
          className="max-w-md flex w-full px-3 md:px-0"
          initialValue={queryPath?.split('-').join(' ')}
          onSubmit={handleOnSubmit}
        />
      </div>

      {data.channel?.accueil?.medias && (
        <Carrousel height={400} medias={data.channel.accueil.medias} />
      )}
      <Content className="md:mt-24" size="lg">
        <Media
          className="md:hidden mx-auto mb-12"
          media={{
            height: 2160,
            id: 'r6JwkhOCXiek6LAfjh72',
            name: 'Barrois antiques',
            origin: 'https://barroisantiq-e4ce0-images.web.app',
            pathname: 'barrois/DX3V-Ggaxa5aGFkQl3U1w.jpg',
            type: 'image/jpeg',
            width: 3001,
          }}
        />

        <p className="text-lg max-w-screen-md mx-auto text-center">
          {data.channel?.accueil?.description || t('pages.accueil.subtitle')}
        </p>
        <h2 className="mt-12 mb-12 text-center text-3xl">
          {t('pages.accueil.categories.title')}
        </h2>
        <SliderSC>
          <Slider {...settings}>
            {data.channel?.accueil?.features &&
              data.channel.accueil.features.map(category => {
                const description =
                  'en' === language &&
                  category.traductions &&
                  category.traductions.en &&
                  category.traductions.en.description
                    ? category.traductions.en.description
                    : category.description;

                const libelle =
                  'en' === language &&
                  category.traductions &&
                  category.traductions.en &&
                  category.traductions.en.libelle
                    ? category.traductions.en.libelle
                    : category.libelle;

                return (
                  <Category
                    key={category.id}
                    description={description}
                    id={category.id}
                    medias={category.medias}
                    title={libelle}
                  />
                );
              })}
          </Slider>
        </SliderSC>
      </Content>
    </Layout>
  );
};

export default IndexPage;

export const query = graphql`
  query {
    channel {
      id
      accueil {
        description
        features {
          id
          description
          libelle
          medias {
            id
            height
            origin
            pathname
            width
          }
          traductions {
            en {
              description
              libelle
            }
          }
        }
        medias {
          id
          height
          origin
          pathname
          width
        }
      }
    }
  }
`;
