import { MediaNode } from '@innedit/innedit-type';
import React, { FC } from 'react';
import Slider from 'react-slick';
import styled from 'styled-components';

import SliderSC from '../../plugins/slick';
import screens from '../../styles/screens';
import Media from '../Media';

const CarrouselSC = styled.div`
  @media screen and (max-width: ${screens.md}) {
    display: none;
  }
`;

interface CarrouselProps {
  height?: number;
  medias: MediaNode[];
}

const Carrousel: FC<CarrouselProps> = function ({ height, medias }) {
  const settings = {
    arrows: false,
    autoplay: true,
    autoplaySpeed: 2000,
    centerMode: true,
    dots: false,
    draggable: false,
    infinite: true,
    pause: false,
    slidesToScroll: 1,
    slidesToShow: 1,
    speed: 500,
    variableWidth: true,
  };

  return (
    <CarrouselSC>
      <SliderSC>
        <Slider {...settings}>
          {medias.map(media => (
            <div key={media.id} className="mx-2">
              <Media height={height} media={media} />
            </div>
          ))}
        </Slider>
      </SliderSC>
    </CarrouselSC>
  );
};

export default Carrousel;
